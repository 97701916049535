<template>
  <Header :header="header"></Header>
</template>

<script>
import Header from '@/components/authenticated/Header'

export default {
  name: 'profile',
  components: {
    Header
  },
  data () {
    return {
      header: {
        type: 'single',
        title: 'Completa tu perfil'
      }
    }
  }
}
</script>

<style scoped>

</style>
